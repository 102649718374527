h1, .font-garamond {
    font-family: "EB Garamond", "Garamond", serif;
    font-weight: 400;
}

h2, h3, h4, h5 {
    font-weight: 400;
}

.bg-trans {
    background-color: rgba(0,0,0, 0.7);
}

.home-link {
    font-family: "EB Garamond", "Garamond", serif;
    font-size: 1.6rem;
    font-weight: 400;
    color: white;
}

svg.white {
    path {
        fill: white
    }
}

.logo-wrap {
    width: 100px;
    height: 85px;
    svg {
        path {
            fill: $app-primary;
        }
    }
}

.digiboostblue {
    background-color: rgb(18,95,168);
}

.cursor-pointer {
    cursor: pointer;
}

.bg-nodes {
    background-image: url(/img/nodes-with-chain.png);
    background-size: contain;
    height: 600px;
    background-repeat: no-repeat;
    background-position-x: center;
}

// Entypo icons
.icon {
    height: 21px;
    width: 21px;
    display: flex;
    svg {
        width: 21px;
        height: 21px;
    }
}

.icon.grey svg path {
    fill: #999;
}

.icon.red svg path {
    fill: #A00;
}

.collapseTagFilterToggle {
    .after:after {
        content: '↑'
    }
    &.collapsed {
        .after:after {
            content: '↓'
        }
    }

}

.mw-500 {
    max-width: 500px;
}
