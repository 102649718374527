// EB Garamond
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;500&display=swap');

// Customise bootstrap, and set site-wide variables.
@import 'variables';

// Include Bootstrap.
@import 'bootstrap-5.2.3/bootstrap';

// Global styles
@import 'global';
