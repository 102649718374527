// -----------------------------------------------------------------------------
// Define variable for CloudForest's SCSS.
// -----------------------------------------------------------------------------

// A range of warm grays
$white:    #fff !default;
$gray-100: #f7f6f4 !default;
$gray-200: #e7e6e4 !default;
$gray-300: #dedcda !default;
$gray-350: #d5d4d3 !default;
$gray-400: #ceccca !default;
$gray-500: #989592 !default;
$gray-600: #888582 !default;
$gray-700: #585450 !default;
$gray-800: #3e3b38 !default;
$gray-900: #282420 !default;
$black:    #000 !default;

// Tweaked Boostrap colors
$app-primary:   #4A596C;
$app-secondary: #8597AF;
$app-info:      #8d915a;
$app-success:   #5aac44;
$app-danger:    #721B00;
$app-warning:   #f8a54b;
$app-inspire:   #f1b308;
$app-light:     $gray-200;
$app-dark:      darken($app-primary, 13%);

// Other colors
$app-very-light: $gray-100;

// Map the Bootstrap theme variables to our app colors.
$theme-colors: (
    "primary":    $app-primary,
    "secondary":  $app-secondary,
    "success":    $app-success,
    "info":       $app-info,
    "warning":    $app-warning,
    "danger":     $app-danger,
    "light":      $app-light,
    "dark":       $app-dark,
    "inspire":    $app-inspire
);

// Create a light copy of each one. 40% is about the same as is applied to alert
// BGs by Bootstrap.
$light-colors: ();

// Create lighter BGs
@each $color, $value in $theme-colors {
    .bg-#{$color}-light {
        background-color: lighten($value, 7%);
    }
}
@each $color, $value in $theme-colors {
    .bg-#{$color}-lighter {
        background-color: lighten($value, 30%);
    }
}

.bg-inspire-lighter-still {
    background-color: lighten($app-inspire, 45%);
}

// Echo Bootstrap's breakpoints for inline media queries without importing all
// of Bootstrap again (ie, in order to use their mixins)
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

// For darker borders
$border-dark: $gray-350;

// Echo Bootstrap's gutter so we can refer to it but not import everything
$bs-gutter: 15px;

// Responsive font sizes
$enable-responsive-font-sizes: true;

// Switch link color.
$link-color: $app-success !default;

// Turn off border-radius
$enable-rounded: false;

// Tweak border-dark
body .border-dark {
    border-color: darken(#d5d4d3, 10%) !important
}

// Headings
$headings-color: $app-primary;

// Aspect ratios
$aspect-ratios: (
  "1x1": 100%,
  "4x3": calc(3 / 4 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "21x9": calc(9 / 21 * 100%),
  "3x4": calc(4 / 3 * 100%)
);